<template>
  <div>
    <!-- DESKTOP -->
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-selection">
      <v-row justify="center">
        <v-col class="pl-0 pr-0 text-left" cols="12">
          <h1 class="selection__title">Programas BRM</h1>
        </v-col>
        <v-col cols="12">
          <v-row align="center">
            <template v-for="data in filteredListActionSelection">
              <v-col v-if="data.permisson" class="pl-0 pr-6 selection__col-card" :key="data.id" cols="2">
                <v-card outlined elevation="0" class="selection__card ">
                  <v-card-title>
                    <v-layout justify-center>
                      <img :src="data.icon" :class="data.class" :width="data.widthImage ? undefined : '100'"
                        :height="data.heightImage ? undefined : '120'"
                        :style="data.widthImage ? { width: data.widthImage } : {}" />
                    </v-layout>
                  </v-card-title>
                  <v-card-text style="height: 80px" class="text-left">
                    <p class="title-selection">{{ data.title }}</p>
                    <p class="text-selection">{{ data.description }}</p>
                  </v-card-text>
                  <v-card-actions class="pt-0 pa-xl-1 pt-xl-5 mt-0 mb-3 mt-3">
                    <v-hover v-slot="{ hover }">
                      <v-btn @click="go(data)" block rounded style="color: #fff" :style="{
                        'background-color': hover ? '#324CA1' : '#466BE3',
                      }" class="pa-xl-0 mt-10">
                        {{ data.btnName }}
                      </v-btn>
                    </v-hover>
                  </v-card-actions>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- MOBILE -->
    <div class="mb-sm-selection">
      <!-- Similar changes can be made for the mobile version -->
    </div>

    <Alert :open="message.dialog" :text="message.sms" :title="message.title" :type="message.type"
      :redirect="message.redirect" @close="message.dialog = false">
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
import { watch } from "less";
export default {
  components: {
    Alert,
  },
  data() {
    return {
      origin: window.location.origin,
      loadingAll: false,
      infoToken: {
        document: "",
      },
      name: "",
      apps: [],
      listActionSelection: [
        {
          mobileVersion: true,
          icon: origin + "/icon/life-control-1.svg",
          description:
            "La sección de control de vida le facilita encontrar asesoría para sus planes futuros.",
          title: "Control de vida",
          redirect: "lifeControl",
          externalRedirect: false,
          permisson: true,
          btnName: "Ingresar",
          id: 1,
        },
        {
          mobileVersion: true,
          icon: origin + "/icon/references-1.svg",
          description:
            "Revisa las vacantes y refiere aquí a tus referidos.",
          title: "Le tengo la ficha",
          redirect: "referenceCard",
          externalRedirect: false,
          permisson: true,
          btnName: "Ingresar",
          id: 2,
        },
        {
          mobileVersion: true,
          icon: origin + "/icon/reconocimientos-v2.png",
          description: "Descubre a nuestros ganadores y da el siguiente paso hacia la excelencia. ¡Únete a ellos!",
          title: "Reconocimientos",
          redirect: "ReconocimineosParaTi",
          externalRedirect: false,
          permisson: true,
          btnName: "Ingresar",
          class: "reconocimientosCard",
          id: 3,
          nombre_app: "Reconocimientos",
        },
        {
          mobileVersion: true,
          icon: origin + "/icon/Convenios-v1.png",
          description: "Accede a más de 50 aliados y disfruta de beneficios exclusivos solo para ti.",
          title: "Convenios",
          redirect: "convenios",
          externalRedirect: false,
          permisson: false,
          btnName: "Ingresar",
          class: "reconocimientosCard",
          id: 4,
          widthImage: "auto",
          nombre_app: "Convenios",
        },
        {
          mobileVersion: true,
          icon: origin + "/icon/logo_Galileo.png",
          description: "Descubre cómo BRM se organiza y cómo puedes crecer y alcanzar nuevos retos.",
          title: "Galileo",
          redirect: "#",
          externalRedirect: false,
          permisson: false,
          btnName: "Ingresar",
          class: "reconocimientosCard",
          id: 5,
          nombre_app: "Galileo",
        }
      ],
      name: "Angie",
      token: "",
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
    };
  },
  computed: {
    filteredListActionSelection() {
      const userCountry = this.getCountry; // Obtener el país desde getCountry

      if (!userCountry) return []; // Si el país no está definido, retorna una lista vacía

      return this.listActionSelection.filter(item => {
        // Si el item no tiene nombre_app, se muestra siempre
        if (!item.nombre_app) {
          return true;
        }
        
        // Buscar la aplicación correspondiente en this.apps por nombre_app
        const app = this.apps.find(app => app.nombre_app === item.nombre_app);
        
        // Si no se encuentra la aplicación o no tiene países definidos, no mostrar
        if (!app || !app.paises) {
          return false;
        }

        // Verificar si el país del usuario está en la lista de países de la aplicación
        const paisesApp = Array.isArray(app.paises) ? app.paises : [app.paises];
        return paisesApp.includes(userCountry);
      });
    },
    getCountry() {
      const currentUser = this.$store.getters.getUser;  // Obtienes el usuario completo
      return currentUser ? currentUser.country : '';  // Devuelves el país si existe
    },
    getUser() {
      let user = this.$store.getters.getUser;

      if (user) {
        this.loadingAll = false;
        this.country = user.country;
      } else {
        this.loadingAll = true;
      }
    },
    // Computed property para obtener todas las aplicaciones
    getAllApps() {
      let allApps = this.$store.getters.getApps;
      if (allApps.message) {
        this.getApps(allApps.data);
      } else {
        this.loadingAll = true;
      }
    },
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;

      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    go(data) {
      this.$router.push({ name: data.redirect });
    },

    // Método para manejar la lista de aplicaciones
    getApps(allApps) {
      // Guardamos todas las apps en el estado local
      this.apps = allApps;
      
      if (allApps && allApps.length !== 0) {
        let end = allApps.length - 1;
        for (let i = 0; i < allApps.length; i++) {
          const app = allApps[i];
          
          for (let j = 0; j < this.listActionSelection.length; j++) {
            if (this.listActionSelection[j].nombre_app === app.nombre_app) {
              this.$set(this.listActionSelection, j, {
                ...this.listActionSelection[j],
                permisson: true
              });
            }
          }
          if (i === end) {
            this.loadingAll = false;
          }
        }
      } else {
        this.loadingAll = false;
      }
    },

  },
  created() {
    this.getToken();
  },
  mounted() {
  },
  watch: {
    getUser: function (newCount, oldCount) {
      console.log("");
    },
    getCountry(newCountry, oldCountry) {
      console.log(newCountry);
    },
    // Observador para getAllApps
    getAllApps: function (newCount, oldCount) {
      console.log(""); // Placeholder para el manejo de cambios en getAllApps
    },
  },
};
</script>

<style lang="less" scoped>
@import "./../../assets/css/main.less";

.navidad-text {
  display: flex;
}

// .navidad-style4{
//   // background-color: red;
//   background-image: url(../../assets/img/navidad/border-navidad4.png);
//   background-repeat: round;
//   background-position-y: -6px;
// }
.mb-xl-selection {
  display: block;
}

.mb-sm-selection {
  display: none;
}

.title-selection {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #353535;
}

.text-selection {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #626262;
  text-align: center !important;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}

.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.requests-card-selection {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  height: 100px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  border-radius: 10px !important;
  border: 1px solid #466be3 !important;
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
}

.request-project-sign {
  color: #466be3;
  font-family: "RobotoMedium" !important;
  font-weight: 700 !important;
  word-break: normal;
  text-align: center;
  font-size: 2rem !important;
}

.request-txt-project-sign {
  font-family: "RobotoRegular";
  color: #898989 !important;
  font-weight: 300;
  font-size: 1rem;
}

.selection__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.selection__col-card {
  max-width: 280px !important;
}

.selection__card {
  border-radius: 15px !important;
  width: 280px;
}

.selection__card:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {}

/* LAPTOP LARGE */

@media (max-width: 1600px) {}

/* LAPTOP LARGE */

@media (max-width: 1400px) {}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {}

/* TABLET */

@media (max-width: 1023px) {
  .title-selection {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-selection {
    display: none;
  }

  .mb-sm-selection {
    display: block;
    text-align: initial;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .title-selection {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-selection {
    display: none;
  }

  .mb-sm-selection {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .title-selection {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-selection {
    display: none;
  }

  .mb-sm-selection {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .title-selection {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-selection {
    display: none;
  }

  .mb-sm-selection {
    display: block;
    text-align: initial;
  }
}

/*Estilos Santiago */

.reconocimientosCard {
  filter: grayscale(1);
  width: 100%;
}
</style>
